body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background: #1A202C;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

.App {
    text-align: center;
    min-height: 100vh;
    background-color: #0a0b0c;
    color: #d1d5db;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  }
  
  .App-header {
    padding: 2rem 0;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .App-header h1 {
    font-size: 2.5rem;
    margin: 0;
    font-weight: 300;
    letter-spacing: 0.05em;
  }
  
  .subtitle {
    font-size: 0.875rem;
    color: #6b7280;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-top: 0.5rem;
  }
  
  .content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0.5rem;
  }
  
  .input-form {
    background-color: #111214;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    border: 1px solid #1f2937;
    width: 100%;
    box-sizing: border-box;
    max-width: 800px;
    margin: 0 auto 1rem auto;
  }
  
  .form-group {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  label {
    display: block;
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #9ca3af;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  input, select {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.9rem;
    background-color: #1a1b1e;
    border: 1px solid #374151;
    border-radius: 0.25rem;
    color: #e5e7eb;
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  input:focus, select:focus {
    outline: none;
    border-color: #4b5563;
    box-shadow: 0 0 0 2px rgba(75, 85, 99, 0.3);
  }
  
  .submit-button{
    background-color: white;
    color: rgb(73, 67, 67);
  }
  button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 500;
    background-color: #66b4d5;
    color: #080101;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  button:hover {
    background-color: #e7ecf0;
  }
  
  button:disabled {
    background-color: #dde0e4;
    cursor: not-allowed;
  }
  
  .loading-icon {
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #4a97b8;
    border-top: 2px solid #ecf2f7;
    border-radius: 50%;
    margin-right: 0.5rem;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .tweets-container {
    display: grid;
    gap: 1rem;
  }
  
  .tweet-card {
    background-color: #111214;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-start;
    text-align: left;
    border: 1px solid #1f2937;
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .tweet-icon {
    flex-shrink: 0;
    margin-right: 1rem;
    color: #6b7280;
  }
  
  .tweet-card p {
    margin: 0;
    font-size: 0.875rem;
  }
  
  @media (max-width: 640px) {
    .content {
      padding: 1rem;
    }
  
    .input-form {
      padding: 1.5rem;
    }
  
    .App-header h1 {
      font-size: 2rem;
    }
  }
  
  .tweet-display {
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
  }
  
  .tweet-display-title {
    font-size: 1.5rem;
    color: #1d9bf0;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .tweets-grid {
    width: 100%;
    display: grid;
    gap: 2.5rem;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .tweets-grid-short {
    grid-template-columns: repeat(2, 1fr);
    max-width: 1800px;
  }
  
  .tweets-grid-long {
    grid-template-columns: 1fr;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .tweet-card-detailed {
    background-color: #1a1b1e;
    border: 1px solid #2d3748;
    border-radius: 1rem;
    padding: 2rem;
    height: fit-content;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-right: 2.5rem;
  }
  
  .tweet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #2d3748;
  }
  
  .tweet-number {
    font-size: 0.875rem;
    color: #9ca3af;
    font-weight: 500;
  }
  
  .viral-score {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .score-circle {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .score-inner {
    width: 2.5rem;
    height: 2.5rem;
    background: #1a1b1e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .score-inner span {
    font-size: 0.75rem;
    font-weight: 600;
    color: #ffffff;
  }
  
  .score-label {
    font-size: 0.75rem;
    color: #9ca3af;
  }
  
  .tweet-content {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #e5e7eb;
    padding: 1rem 0;
    white-space: pre-wrap;
    max-height: 600px;
    overflow-y: auto;
    text-align: left;
    padding-right: 20px;
  }
  
  .tweet-paragraph {
    margin: 0 0 1.5rem 0;
  }
  
  .tweet-paragraph:last-child {
    margin-bottom: 0;
  }
  
  .tweet-content::-webkit-scrollbar {
    width: 4px;
    margin-right: 4px;
  }
  
  .tweet-content::-webkit-scrollbar-track {
    background: #1a1b1e;
    margin: 4px;
  }
  
  .tweet-content::-webkit-scrollbar-thumb {
    background: #4b5563;
    border-radius: 4px;
  }
  
  @media (max-width: 1200px) {
    .tweets-grid-short {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .tweets-grid-short {
      grid-template-columns: 1fr;
      max-width: 600px;
      margin: 0 auto;
    }
  }
  
  .content-type-selector {
    margin-bottom: 2rem;
  }
  
  .selector-label {
    display: block;
    font-size: 0.75rem;
    color: #9ca3af;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .options-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  
  .option-card {
    background: #1a1b1e;
    border: 2px solid #2d3748;
    border-radius: 1rem;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .option-card.selected {
    border-color: #3b82f6;
    background: linear-gradient(45deg, rgba(59, 130, 246, 0.1), rgba(37, 99, 235, 0.05));
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5), 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .option-card:hover:not(.selected) {
    border-color: #4b5563;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .option-card h3 {
    color: #e5e7eb;
    margin-bottom: 0.5rem;
    transition: color 0.2s ease;
    font-size: 1rem;
  }
  
  .option-card.selected h3 {
    color: #3b82f6;
  }
  
  .option-icon {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    display: block;
    transition: transform 0.2s ease;
  }
  
  .option-card.selected .option-icon {
    transform: scale(1.1);
  }
  
  .option-card p {
    font-size: 0.75rem;
    color: #9ca3af;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .options-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .tweets-grid-thread {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .tweets-grid-thread .tweet-card-detailed {
    width: 100%;
  }
  
  .thread-card {
    position: relative;
    margin-bottom: 3rem !important;
  }
  
  .thread-card:last-child {
    margin-bottom: 0 !important;
  }
  
  .thread-connector {
    position: relative;
    width: 2px;
    margin: 0 auto;
    background: linear-gradient(180deg, #3b82f6 0%, #1d4ed8 100%);
  }
  
  .connector-dot {
    position: absolute;
    width: 8px;
    height: 8px;
    background: #3b82f6;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .connector-dot:first-child {
    top: -4px;
  }
  
  .connector-dot:last-child {
    bottom: -4px;
  }
  
  .tweets-grid-thread {
    position: relative;
    padding: 2rem;
    background: linear-gradient(180deg, rgba(59, 130, 246, 0.05) 0%, transparent 100%);
    border-radius: 1.5rem;
  }
  
  .thread-score-container {
    position: sticky;
    top: 1rem;
    z-index: 10;
    background: #1a1b1e;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    border: 1px solid #2d3748;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  
  .thread-score-label {
    font-size: 1rem;
    color: #9ca3af;
    font-weight: 500;
  }
  
  .error-message {
    color: #ef4444;
    background: rgba(239, 68, 68, 0.1);
    border: 1px solid rgba(239, 68, 68, 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 1rem 0;
    text-align: center;
  }
  
  textarea {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.875rem;
    background-color: #1a1b1e;
    border: 1px solid #374151;
    border-radius: 0.25rem;
    color: #e5e7eb;
    transition: border-color 0.2s, box-shadow 0.2s;
    resize: vertical;
    min-height: 80px;
    font-family: inherit;
  }
  
  textarea:focus {
    outline: none;
    border-color: #4b5563;
    box-shadow: 0 0 0 2px rgba(75, 85, 99, 0.3);
  }
  
  textarea::placeholder {
    color: #6b7280;
  }
  
  .option-logo {
    width: 40px;
    height: auto;
    margin-bottom: 0.5rem;
  }
  
  .login-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0a0a0a;
    font-family: system-ui, -apple-system, sans-serif;
  }
  
  .login-container {
    max-width: 400px;
    width: 100%;
    padding: 2rem;
    text-align: center;
  }
  
  .login-header {
    margin-bottom: 2rem;
  }
  
  .login-header h1 {
    font-size: 2.5rem;
    font-weight: 300;
    color: rgb(226, 232, 240);
    margin-bottom: 0.5rem;
    letter-spacing: 0.025em;
    text-shadow: 0 0 20px rgba(148, 163, 184, 0.15);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(226, 232, 240, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .login-header p {
    font-size: 0.875rem;
    color: #6B7280;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  
  .google-login-btn {
    width: 100%;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: white;
    color: black;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
  }
  
  .google-login-btn:hover {
    background-color: #f3f4f6;
  }
  
  .google-icon {
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    left: 1rem;
  }
  
  .tweet-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  
  .tweet-share-btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    background-color: #4b5563;
    color: #ffffff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .tweet-share-btn:hover {
    background-color: #3b82f6;
  }