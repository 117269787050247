.subscription-page {
  min-height: 100vh;
  padding: 2rem;
  background-color: #0a0a0a;
  color: #ffffff;
}

.plans-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 2rem auto;
}

.plan-card {
  background: #1a1b1e;
  border: 2px solid #2d3748;
  border-radius: 1rem;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.plan-card.selected {
  border-color: #3b82f6;
  background: linear-gradient(45deg, rgba(59, 130, 246, 0.1), rgba(37, 99, 235, 0.05));
  transform: scale(1.02);
}

.plan-card:hover:not(.selected) {
  border-color: #4b5563;
}

.price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #3b82f6;
  margin: 1rem 0;
}

.duration {
  color: #9ca3af;
  margin-bottom: 1rem;
}

.payment-container {
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background: #1a1b1e;
  border-radius: 1rem;
}

.subscription-container {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #121212;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
}

h2 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 2rem;
  font-weight: 600;
}

.pricing-plans {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
}

.plan-card {
  background: #1e1e1e;
  border-radius: 1.5rem;
  padding: 2.5rem;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}

.plan-card h3 {
  font-size: 2rem;
  color: #3b82f6;
  margin-bottom: 1rem;
  font-weight: 500;
}

.price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  margin: 1rem 0;
}

.duration {
  color: #9ca3af;
  margin-bottom: 1rem;
}

.plan-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.plan-card li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #e5e7eb;
  font-size: 1rem;
}

.plan-card li svg {
  width: 24px;
  height: 24px;
  color: #3b82f6;
  margin-right: 0.5rem;
}

.popular-tag {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

button {
  width: 100%;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #2563eb;
  transform: translateY(-2px);
}

.loading-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  color: #ffffff;
}

.loading-icon::before {
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #ffffff;
  border-top: 2px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 0.5rem;
}

.payment-divider {
  text-align: center;
  margin: 15px 0;
  position: relative;
  color: #666;
}

.payment-divider::before,
.payment-divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}

.payment-divider::before {
  left: 0;
}

.payment-divider::after {
  right: 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}