.contact-us {
  padding: 2rem;
  background-color: #f9f9f9;
  color: #333;
  max-width: 800px; /* Limit the width for better readability */
  margin: 0 auto; /* Center the content */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.contact-us h1 {
  font-size: 2.5rem; /* Increased font size */
  margin-bottom: 1rem;
  color: #1a1b1e; /* Darker color for headings */
}

.contact-us h2 {
  font-size: 1.75rem; /* Increased font size for subheadings */
  margin-top: 1.5rem;
  color: #1a1b1e; /* Darker color for subheadings */
}

.contact-us p {
  margin-bottom: 1rem;
  line-height: 1.6; /* Improved line height for readability */
}

.contact-us ul {
  list-style-type: none;
  padding: 0;
}

.contact-us li {
  margin-bottom: 0.5rem;
} 