.privacy-policy {
  padding: 2rem;
  background-color: #f9f9f9;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #1a1b1e;
}

.privacy-policy h2 {
  font-size: 1.75rem;
  margin-top: 1.5rem;
  color: #1a1b1e;
}

.privacy-policy p {
  margin-bottom: 1rem;
  line-height: 1.6;
} 